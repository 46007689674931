// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[production]6.0-10324(92e4e932f8)-C08/11/2024-09:13:12-B08/11/2024-09:18:20' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[production]6.0-10324(92e4e932f8)-C08/11/2024-09:13:12-B08/11/2024-09:18:20",
  branch: "production",
  latestTag: "6.0",
  revCount: "10324",
  shortHash: "92e4e932f8",
  longHash: "92e4e932f86504ada9fd7f32b5c8d712de06301f",
  dateCommit: "08/11/2024-09:13:12",
  dateBuild: "08/11/2024-09:18:20",
  buildType: "Ansible",
  } ;
